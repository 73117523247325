import React from "react"
import {  graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

// import PostDetails from "../sections/blog/PostDetails"
// import Comments from "../sections/blog/Comments"
// import Sidebar from "../sections/blog/Sidebar"

const BlogDetails = ({ data }) => {
  const post = data.contentfulEasytestBlogPost
  const title = post.title
  const slug = post.slug
  // const publishDate = get(post, "publishDate")
  // const image = get(post, "heroImage")
  // const author = get(post, "author.name")
  const og_image = post.heroImage.gatsbyImageData.images.fallback.src
  const description = post.description.description
  const body_html = post.content.childMarkdownRemark.html

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://easytest.ma/blog/${slug}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={og_image} />
      </Helmet>
      <Layout withPattern={false}>
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32 relative py-16 bg-white overflow-hidden">
          {/* <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div
              className="relative h-full text-lg max-w-prose mx-auto"
              aria-hidden="true"
            >
              <svg
                className="absolute top-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
              <svg
                className="absolute bottom-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                />
              </svg>
            </div>
          </div> */}
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 text-primary-600 font-extrabold tracking-tight sm:text-4xl">
                  {title}
                </span>
              </h1>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                {description}
              </p>
            </div>
            <div className="contentful-blog-post">
              {ReactHtmlParser(body_html)}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default BlogDetails

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulEasytestBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      slug
      description {
        description
      }
      heroImage {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          formats: [AUTO, WEBP, AVIF]
        )
      }
      tags
      author {
        name
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
